import React, { Component } from "react";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        console.log('组件报错了：', error, info);
        // eslint-disable-next-line react/no-unused-state
        this.setState({ hasError: true, error, info });
    }

    render() {
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div onClick={() => { window.location.reload() }} style={{ textAlign: "center", marginTop: "60px" }}>
                    <div style={{ textAlign: "center", marginTop: "20px", color: "#333" }}>页面加载出错了，请点击重试</div>
                </div>
            );
        }
        // eslint-disable-next-line react/destructuring-assignment
        return this.props.children;
    }
}

export default ErrorBoundary;
