/* eslint-disable */
import React, { useEffect } from "react";
import localRedux from '@/utils/localRedux'
import useData from '@/utils/useData';
import ErrorBoundary from './cpts/ErrorBoundary'
import Routes from './pages/routes';
import noDatas from '@/A_images/nodate.png'
import Loading from "@/pages/A_layout/loading";

const App = () => {
    // @ts-ignore
    const [state, setState] = useData({ user: {}, config: {}, loading: true, error: false })
    let { loading, error } = state;

    const getUser = async () => {
        // //@ts-ignore
        // let tgUser = {};
        // //@ts-ignore
        // if(window.Telegram && !window.Telegram.WebApp){
        //     //@ts-ignore
        //     tgUser = window.Telegram.WebApp.initDataUnsafe.user || {};
        // }

        setState({
            loading: false,
            error: false
        })
    }

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {
        //@ts-ignore
        let tgUser = {};
        //@ts-ignore
        if(window.Telegram && window.Telegram.WebApp){
            console.log('开始获取到：');
            //@ts-ignore
            console.log(window.Telegram.WebApp);
            //@ts-ignore
            tgUser = window.Telegram.WebApp.initDataUnsafe.user || {
                allows_write_to_pm: true,
                first_name: "Yi",
                id: 6421236536,
                language_code: "zh-hans",
                last_name: "Dian"
            };
            console.log('获取到user：',tgUser);

            // @ts-ignore
            window.Telegram.WebApp.onEvent('backButtonClicked',()=>{
                localStorage.setItem('isInit','');
            })

        }
        setState({
            user: tgUser
        })
        //@ts-ignore
    }, [window.Telegram])

    if (loading) {
        return <Loading>Loading...</Loading>
    }
    // @ts-ignore
    if(!window.Telegram || !window.Telegram.WebApp){
        return <div onClick={()=>{ window.location.reload()}} style={{ textAlign: "center", marginTop: "60px" }}>
            <a href='https://t.me/lineAi_bot/lineAiStory' style={{ textAlign: "center", marginTop: "20px", color: "#333" }}>请在Telegram中使用！</a>
        </div>
    }else{
        // @ts-ignore
        window.Telegram.WebApp.ready();
        // @ts-ignore
        window.Telegram.WebApp.expand();
        // @ts-ignore
        // window.Telegram.WebApp.BackButton.onClick(function () {
        //     localStorage.setItem('isInit','');
        // });
        // // @ts-ignore
        // window.Telegram.WebApp.BackButton.offClick(function () {
        //     localStorage.setItem('isInit','');
        // });;
        //
        // // @ts-ignore
        //  if(localStorage.getItem('isInit') != "1"){
        //      localStorage.setItem('isInit','1');
        //      console.log('reload1111');
        //      location.reload();
        //  }



    }


    if (error) {
        return <div onClick={()=>{ window.location.reload()}} style={{ textAlign: "center", marginTop: "60px" }}>
            <img src={noDatas}  style={{  display: "block",width: "290px",margin: "0 auto"}}/>
            <div style={{ textAlign: "center", marginTop: "20px", color: "#333" }}>页面加载出错了，请点击重试</div>
        </div>
    }

    return (
        <localRedux.localRudexProvider value={state}>
            <ErrorBoundary>
                <Routes />
            </ErrorBoundary>
        </localRedux.localRudexProvider>
    )
}

export default App
