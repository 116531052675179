/**
 * 组件内状态管理
 */
import { useReducer } from "react";
import Immutable from "seamless-immutable";

const useData = (initValue = {}) => {
    const fetchReducer = (state, props) => {
        let { _force, data = {} } = props
        if (_force) {
            return Immutable(data)
        }
        if (typeof props == "function") {
            return props(state)
        }
        return state.merge(props);
    }
    return useReducer(fetchReducer, Immutable(initValue));
}

export default useData