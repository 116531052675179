/* eslint-disable */
// @ts-nocheck
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import useRxjsContext from '@/utils/useRxjsContext';

const setSubject$ = new Subject()
export const set$ = setSubject$.pipe(
    map((data) => state => {
        state = state.merge(data)
        return state
    })
)
// @ts-ignore
const localRedux = useRxjsContext([set$])
// @ts-ignore
export default localRedux