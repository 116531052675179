// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/.store/css-loader@3.6.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".style-module_loading-PDIUq {\n  text-align: center;\n  font-size: 0.16rem;\n  margin: 0.2rem 0;\n  color: #8F61FA;\n}\n", "",{"version":3,"sources":["style.module.less"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAe;EACf,gBAAc;EACd,cAAc;AAChB","file":"style.module.less","sourcesContent":[".loading {\n  text-align: center;\n  font-size: 16px;\n  margin: 20px 0;\n  color: #8F61FA;\n}\n"]}]);
// Exports
exports.locals = {
	"loading": "style-module_loading-PDIUq"
};
module.exports = exports;
