import React from "react";
import ReactDOM from "react-dom";
import { hot } from 'react-hot-loader/root';
import Router from './Router';

// @ts-ignore
require("@/style/app.less")

const App = () => {
    return <Router />
}

const HotApp = hot(App);
// @ts-ignore
// eslint-disable-next-line react/no-deprecated
ReactDOM.render(<HotApp />, document.getElementById("app"))