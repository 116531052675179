import React from 'react';
import styles from "./style.module.less";

const Layout = ({ children, topMenu = true }) => {
  return (
    <div className={styles.home}>
      <div className={styles.content_inline}>
        {children}
      </div>
    </div>
  )
}

export default Layout;
