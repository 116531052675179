import React, { useEffect } from "react";
// @ts-ignore
import { InputItem, Button, Toast } from 'antd-mobile';
import { createForm } from 'rc-form';
import useData from "@/utils/useData";
// @ts-ignore
import Layout from '@/pages/A_layout'
// @ts-ignore
import styles from './styles.module.less'
// @ts-ignore
import { sendSms, login } from '@/services'
// @ts-ignore
const Login = ({ form }) => {
    // @ts-ignore
    const [data, setData] = useData({ uuid: '', type: 'PHONE_CODE', codeText: '发送验证码', isSend: false, phone: undefined });

    // @ts-ignore
    let { type, uuid } = data

    useEffect(() => {

    }, [])
    
    return (
        <Layout topMenu={false}>
            Login
        </Layout>
    )
}

const RegisterWrapper = createForm()(Login);

const LoginCon = (props) => {
    return <RegisterWrapper {...props} />
}
export default LoginCon
