import React from 'react';
import { Icon } from 'antd-mobile';
import styles from './style.module.less'

const Loading = ({ children }) => {
    return (
        <div className={styles.loading}>
            <Icon color="#8F61FA" type="loading" />
            {children && <div>{children}</div>}
        </div>
    )
}

export default Loading