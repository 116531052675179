// @ts-ignore
import axios from "axios";

const { baseUrl } = window.AppConfig

const netfetch = axios.create({
    //@ts-ignore
    baseURL: baseUrl || "https://www.telegram-guide.com/novel-api",
    timeout: 15000,
    headers: {
        "Content-Type": "application/json"
    }
});

// 添加请求拦截器
netfetch.interceptors.request.use((config) => {
    let user:any = {};
    let initDataUnsafe:any = {};
    // @ts-ignore
    if (window.Telegram && window.Telegram.WebApp) {
        // @ts-ignore
        initDataUnsafe = window.Telegram.WebApp.initDataUnsafe || {};
        // @ts-ignore
        user = initDataUnsafe.user || {};
    }

    // user = {"id":6421236536,"first_name":"Yi","last_name":"Dian","language_code":"zh-hans","allows_write_to_pm":true};

    // @ts-ignore
    config.headers = {
        // Authorization: sessionStorage.getItem('token') || '',
        tgdata: JSON.stringify(user),
        chatId: user.id,
        //@ts-ignore
        ...config.headers
    };

    if (config.method == 'get') {
        // @ts-ignore
        config.params = {
            chatId: user.id,
            ...config.params,
            timestamp: Date.now()
        };
    }
    if (config.method == 'post') {
        config.data = {
            chatId: user.id,
            ...config.data,
        };
    }
    return config;
}, () => {
    let err = {
        success: false,
        code: "9001",
        msg: "请求时发生错误了",
        data: ""
    }
    return err;
});

// 添加响应拦截器
netfetch.interceptors.response.use((response) => {
    let { data } = response;
    console.log('response.data', data)
    if (data.code == 200 || data.success) {
        return { ...response.data, code: 200, success: true };
    }
    return response.data;
}, ({ response }) => {
    console.log("response", response)
    // 对响应错误做点什么
    let err = {
        success: false,
        code: "4001",
        msg: response.data.msg || '服务器繁忙，请稍后再试！',
        data: ""
    }
    return err;
});

export default netfetch;
