// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/.store/css-loader@3.6.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".style-module_home-1YBGB {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.style-module_content_inline-3MC3i {\n  flex: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  background: #f2f3f5;\n}\n", "",{"version":3,"sources":["style.module.less"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,OAAO;EACP,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;AACrB","file":"style.module.less","sourcesContent":[".home {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.content_inline {\n  flex: 1;\n  overflow-x: hidden;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  background: #f2f3f5;\n}\n"]}]);
// Exports
exports.locals = {
	"home": "style-module_home-1YBGB",
	"content_inline": "style-module_content_inline-3MC3i"
};
module.exports = exports;
