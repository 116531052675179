/**
 * 主頁面
 */
import React, { useEffect } from "react";
// @ts-ignore
import { Toast, Carousel } from 'antd-mobile';
import Cookies from 'js-cookie'
import useData from "@/utils/useData";
import Layout from '@/pages/A_layout'
import downImage from '../../A_images/downIcon.png'
import upImage from '../../A_images/upIcon.png'
// @ts-ignore
import { getTabList } from '@/services/tgIndex';
import localRedux from "@/utils/localRedux";
import StoryList from "./Comp/StoryList";

// @ts-ignore
import styles from './styles.module.less'

const Home = () => {
    // @ts-ignore
    let hd_token = Cookies.get('creator-token');
    // @ts-ignore
    const { context } = localRedux.getContext();
    // @ts-ignore
    const { user } = context;
    // @ts-ignore
    const [data, setData] = useData({ menu: {}, hotId: null, version: null, menuId: null, tabList: [], storyList: [], tabShow: false, isShowAction: false });
    // @ts-ignore
    let { tabShow, tabList, version, isShowAction, menuId, hotId, menu } = data;
    // @ts-ignore
    const getStoryTab = async () => {
        Toast.loading('Loading...');
         let res: any = await getTabList({pageNo:1,pageSize:100});
        if (!res.success) {
            return Toast.info("Error:" + res.msg, 2);
        }
        Toast.hide()
        // eslint-disable-next-line no-empty-pattern
        let sc:any = [{id:'-1',name:"我的收藏"}];

        let list:any = res.data.records || [];
        if (list.length <= 0) {
            return Toast.info('no data', 2);
        }
        //热门id
        // let menuNow = list.find(m => m.categoryType == 'hot');

        setData({
            tabList: [...sc,...list],
            isShowAction: list.length >= 3,
            version: Date.now(),
            menuId: list[0].id,
            menu: list[0],
        });
    }

    useEffect(() => {
        getStoryTab();
    }, [])



    const changeTab = (menu) => {
        let newList = tabList.filter((tg) => tg.id != menu.id);
        console.log(newList)
          newList.splice(1, 0, menu);
        setData({ tabList:newList, menu, menuId: menu.id, tabShow: false });
    }

    return (
        // <IsLogin>
            <Layout topMenu={false}>
                <div className={styles.homeIndx}>
                    <div className={`${styles.topTabMain} ${tabShow ? styles.expanded : ''}`}>
                        <div className={styles.topTab}>
                            {tabList && tabList.length > 0 && tabList.map((menu, index) => {
                                // eslint-disable-next-line no-nested-ternary
                                return index <= 3 ? <div key={menu.id} className={menuId != menu.id ? "tg-btn-tab" : "tg-btn-select"} onClick={() => { changeTab(menu) }}>{`${menu.name}`}</div>
                                    : tabShow ? <div key={menu.id} className={menuId != menu.id ? "tg-btn-tab" : "tg-btn-select"} onClick={() => { changeTab(menu) }}>{`${menu.name}`}</div>
                                    : null
                            })}

                        </div>
                        { isShowAction && <div className={styles.hideTab} onClick={() => { setData({ tabShow: !tabShow }) }}>
                            {tabShow ? <span>收起 <img src={upImage} />  </span> : <span>展开全部 <img src={downImage} /> </span>}
                                          </div> }
                    </div>

                   <StoryList tabId={menuId} tab={menu} key={version} />

                </div>
            </Layout>
       // </IsLogin>
    )
}

export default Home
