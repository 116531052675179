/* eslint-disable */
// @ts-nocheck
import React, { createContext, useState, useContext, useEffect } from "react";
import Immutable from "seamless-immutable";

import { merge, Subject } from 'rxjs';
import { scan, startWith, map } from 'rxjs/operators';

export const useRxjs = ($stream = []) => {
    if (typeof $stream == 'object' && !Array.isArray($stream)) {
        $stream = [$stream]
    }
    // @ts-ignore
    const callBack$ = new Subject().pipe(
        // @ts-ignore
        map(callback => state => callback(state))
    )
    const set$ = new Subject().pipe(
        map(data => state => state.merge(data))
    )
    const setIn$ = new Subject().pipe(
        map(({ payload, data }) => state => {
            if (typeof payload == "string") {
                payload = [payload];
            }
            state = state.setIn(payload, data);
            return state
        })
    )
    // @ts-ignore
    const set = payload => set$.next(payload)
    // @ts-ignore
    const setIn = (payload, data) => setIn$.next({ payload, data })
    // @ts-ignore
    const callback = payload => callBack$.next(payload)
    const createState = (value = {}) => {
        const initState = Immutable(value)
        const [state, setState] = useState(initState)
        useEffect(() => {
            const InitStates$ = merge(set$, setIn$, callBack$)
            const mergeStates$ = $stream.reduce((str$, in$) => merge(str$, in$), InitStates$)
            const states$ = mergeStates$.pipe(
                startWith(initState),
                scan((state, reducer) => {
                    if (typeof reducer != 'function') {
                        return state.merge(reducer)
                    }
                    return reducer(state)
                })
            )
            let state$ = states$.subscribe((state) => {
                if (initState == state) {
                    return
                }
                setState(state)
            })
            return () => state$.unsubscribe()
        }, [])
        return [state, { set, setIn, callback }]
    }
    return [createState, { set, setIn, callback }]
}

const useRxjsContext = ($stream = []) => {
    const [createState, dispatch] = useRxjs($stream)
    // @ts-ignore
    const context = createContext();
    const getContext = () => useContext(context)
    const localRudexProvider = ({ value = {}, children }) => {
        // @ts-ignore
        const [state] = createState(value)
        let contextData = {
            context: state,
            dispatch
        };
        return <context.Provider value={contextData}>{children}</context.Provider>;
    }
    return { getContext, localRudexProvider, dispatch };
}

export default useRxjsContext