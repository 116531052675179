import fetch from "@/utils/fetch";

// 获取tab
export const getTabList = params => fetch.get('/tg/v1/app/mask/type/list', { params });

export const getStoryListByTypeFn = params => fetch.get('/tg/v1/app/mask/list', { params });

export const getStoryDetail = params => fetch.get('/tg/v1/app/mask/one', { params });


// 收藏
export const collectFn = data => fetch.post('/tg/v1/app/mask/collect', data);

// 浏览
export const browerFn = data => fetch.post('/novel/view', data);

// 聊天
export const goTalkFn = data => fetch.post('/tg/v1/app/mask/experience', data);

// 获取story
// export const getStorysByType = data => fetch.post('/v2/app/act/volunteer/add', data);
