/**
 * 主頁面
 */
import React, { useEffect } from "react";
// @ts-ignore
import { Toast, Carousel } from 'antd-mobile';
import useData from "@/utils/useData";
// @ts-ignore
import collectionsImg from '../../../A_images/collections.png'
// @ts-ignore
import viewsImg from '../../../A_images/views.png'
// @ts-ignore
import {getStoryListByTypeFn, collectFn, browerFn, goTalkFn} from '@/services/tgIndex'
// @ts-ignore
import styles from '../styles.module.less'
import ImageError from "@/A_images/imageerror.png";

//@ts-ignore
const StoryList = ({ tabId, tab }) => {
    // @ts-ignore
    const [data, setData] = useData({ init:true,selectedStoryIndex: 0, storyList: [] });
    // @ts-ignore
    let { storyList,init } = data;
    // @ts-ignore
    const getStoryListByType = async () => {
        Toast.loading('Loading...');
        let res1: any = await getStoryListByTypeFn({
            "maskTypeId": tabId, // 分类ID(必传)
            "pageNum": 1, // 当前页,默认1
            "pageSize": 100 // 每页数据量,默认10
        });
        Toast.hide()
        if (!res1.success) {
            return Toast.info("Error:" + res1.msg, 2);
        }
        if(!res1.data){
            return setData({ storyList: [] });
        }
        setData({ storyList: res1.data.records });

        // if(init){
        //     setTimeout(()=>{
        //         //@ts-ignore
        //         window.Telegram && window.Telegram.WebApp.expand();
        //         setData({ init:false});
        //     },500)
        // }


    }

    useEffect(() => {
        if (tabId) {
            getStoryListByType();
        }
    }, [tabId])


    const colectGFn = async (story, collectFlag) => {
        Toast.loading('Loading...');
       let res: any = await collectFn({ "bizId": story.id,type:"0" });
        Toast.hide()
        if (!res.success) {
            return Toast.info("Error:" + res.msg, 2);
        }
        Toast.success('操作成功', 2);
        let newList = [...storyList];
        newList = storyList.map((st1) => {
            if (st1.id == story.id) {
                return { ...st1, isCollect: collectFlag == -1 ? 0 : 1 }
            }
            return st1;
        })
        //changeCollectFn(collectFlag)
        setData({ storyList: newList });
    }

    const goTalk = async (mask) => {
        Toast.loading('Loading...');
        let res1: any = await goTalkFn({ "bizId": mask.id,type:2 });
        if (!res1.success) {
            return Toast.info("Error:" + res1.msg, 2);
        }
        Toast.hide()
        //添加浏览量
        //browerFn({ "bizId": mask.id });

        //@ts-ignore
        window.Telegram && window.Telegram.WebApp.close()
    }

    return (
        <div className={styles.StoryListCon}>
            { storyList.length > 0 && storyList.map((story) => {
                // eslint-disable-next-line react/no-array-index-key
                return <div key={story.id} className={styles.listLi}>
                    { story.tag && <div className={styles.li_type}>{story.tag.split(",")[0]} </div>}
                    <div className={styles.li_l}>
                        <img
                            className={styles.coverIMG}
                            src={story.logo || ImageError}
                            onError={(e:any) => {
                                e.target.src = `${ImageError}`;
                            }}
                        />
                    </div>
                    <div className={styles.storyConLis}>
                        <div className={styles.storyTi}>{story.maskName}</div>
                        {/*<div className={styles.storyAuther}>{`@ ${story.author}`}</div>*/}
                        <div className={styles.introduction}>{`简介：${story.intro.length > 25 ? story.intro.substr(0, 25) + '...' : story.intro}`}</div>
                        <div className={styles.viCol}>
                            {/*<span> <img src={viewsImg} /> {story.viewCount}</span>*/}
                            {/*<span style={{ marginLeft: '10px' }}> <img src={collectionsImg} /> {story.collectCount}</span>*/}
                        </div>
                        <div className={styles.listBtnss}>
                            {story.isCollect == "1" ? <div className={`tg-btn-select ${styles.goB}`} onClick={() => { colectGFn(story, -1) }}>取消收藏</div>
                                : <div className={`tg-btn-select ${styles.goB}`} onClick={() => { colectGFn(story, 1) }}>收藏</div>}

                                <div className={`tg-btn ${styles.goB}`} onClick={()=>goTalk(story)}>开始体验</div>

                        </div>
                    </div>

                       </div>
            })}

        </div>
    )
}

export default StoryList
