import React from "react";
import { HashRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import Home from './Home';
import Login from './Login';
// const DefaultCpt = () => {
//     return <div>Default</div>;
// };

const Routes = () => {
    // @ts-ignore
    return (
        // @ts-ignore
        <Router>
            <Switch>
                <Route path="/home" exact component={Home} />
                <Route path="/login" exact component={Login} />
                <Redirect path="*" to="/home" />
            </Switch>
        </Router>
    )
}

export default Routes;
