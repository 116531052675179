(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("antd-mobile"), require("rxjs"), require("rxjs/operators"), require("react-router-dom"), require("seamless-immutable"), require("react-dom"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "antd-mobile", "rxjs", "rxjs/operators", "react-router-dom", "seamless-immutable", "react-dom", "axios"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("antd-mobile"), require("rxjs"), require("rxjs/operators"), require("react-router-dom"), require("seamless-immutable"), require("react-dom"), require("axios")) : factory(root["react"], root["antd-mobile"], root["rxjs"], root["rxjs/operators"], root["react-router-dom"], root["seamless-immutable"], root["react-dom"], root["axios"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__23__, __WEBPACK_EXTERNAL_MODULE__27__, __WEBPACK_EXTERNAL_MODULE__86__, __WEBPACK_EXTERNAL_MODULE__92__) {
return 